import { graphql, PageProps } from 'gatsby'

import { WpPrograma } from '@_types/graphql-types'

import FlexPage from '@components/FlexPage'

type PageData = {
  singlePage: WpPrograma
}

function page({ data: { singlePage } }: PageProps<PageData>) {
  return <FlexPage data={singlePage} />
}

export default page

export const pageQuery = graphql`
  query GET_FLEXIBLE_PROGRAM($id: String!) {
    singlePage: wpPrograma(id: { eq: $id }) {
      title
      featuredImage {
        node {
          src: sourceUrl
          altText
          localFile {
            extension
            publicURL
            childImageSharp {
              full: gatsbyImageData(
                quality: 75
                placeholder: DOMINANT_COLOR
                layout: FULL_WIDTH
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      seo {
        ...SEO
      }
      acfPageFields {
        content {
          ... on WpPrograma_Acfpagefields_Content_SimpleText {
            bgColor
            text
            title
            fieldGroupName
          }
          ... on WpPrograma_Acfpagefields_Content_SimpleTextWithImage {
            bgColor
            invertColumns
            text
            title
            videoOrImage
            video
            image {
              ...FeaturedImageFields
            }
            fieldGroupName
          }
          ... on WpPrograma_Acfpagefields_Content_TwoColumnsText {
            bgColor
            text
            text2
            title
            fieldGroupName
          }
          ... on WpPrograma_Acfpagefields_Content_TwoColumnsTextWithImage {
            invertColumns
            bgColor
            image {
              ...FeaturedImageFields
            }
            text
            text2
            title
            fieldGroupName
          }
          ... on WpPrograma_Acfpagefields_Content_Tabs {
            bgColor
            title
            fieldGroupName
            abas {
              content {
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_SimpleText {
                  bgColor
                  text
                  title
                  fieldGroupName
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_SimpleTextWithImage {
                  bgColor
                  invertColumns
                  fieldGroupName
                  videoOrImage
                  video
                  image {
                    ...FeaturedImageFields
                  }
                  text
                  title
                  fieldGroupName
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_TwoColumnsText {
                  bgColor
                  text
                  text2
                  title
                  fieldGroupName
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_Gallery {
                  bgColor
                  fieldGroupName
                  images {
                    ...FeaturedImageFields
                  }
                  text
                  title
                  fieldGroupName
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_TwoColumnsTextWithImage {
                  invertColumns
                  bgColor
                  text
                  text2
                  title
                  image {
                    ...FeaturedImageFields
                  }
                  fieldGroupName
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_IconWithText4Columns {
                  splitIntoLines
                  bgColor
                  fieldGroupName
                  columns {
                    text
                    title
                    svgIcon {
                      iconColor
                      iconType
                    }
                  }
                  text
                  title
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_BoxWithIconAndCta3Columns {
                  bgColor
                  boxColor
                  borders
                  columns {
                    cta {
                      target
                      title
                      url
                    }
                    svgIcon {
                      iconColor
                      iconType
                    }
                    text
                    title
                  }
                  text
                  title
                  fieldGroupName
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_BoxWithIconAndCtaVertical3Lines {
                  bgColor
                  boxColor
                  title
                  text
                  fieldGroupName
                  columns {
                    svgIcon {
                      iconColor
                      iconType
                    }
                    cta {
                      target
                      title
                      url
                    }
                  }
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_TextWithImageAndCta {
                  bgColor
                  fieldGroupName
                  title
                  invertColumns
                  text
                  cta {
                    target
                    title
                    url
                  }
                  image {
                    ...FeaturedImageFields
                  }
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_SimpleCarousel {
                  bgColor
                  imageSizes
                  fieldGroupName
                  title
                  perView
                  images {
                    image {
                      ...FeaturedImageFields
                    }
                  }
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_Team {
                  bgColor
                  description
                  title
                  fieldGroupName
                  teamMembers {
                    description
                    fieldGroupName
                    name
                    role
                    photo {
                      ...FeaturedImageFields
                    }
                  }
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_Faq {
                  bgColor
                  fieldGroupName
                  title
                  questions {
                    question
                    answer
                  }
                }
                ... on WpPrograma_Acfpagefields_Content_Tabs_abas_Content_BlogPosts {
                  bgColor
                  title
                  description
                  fieldGroupName
                  category {
                    posts {
                      nodes {
                        ...PostFields
                        featuredImage {
                          node {
                            ...FeaturedImageFields
                          }
                        }
                      }
                    }
                  }
                }
              }
              title
            }
          }
          ... on WpPrograma_Acfpagefields_Content_Gallery {
            bgColor
            text
            title
            images {
              ...FeaturedImageFields
            }
            fieldGroupName
          }
          ... on WpPrograma_Acfpagefields_Content_IconWithText4Columns {
            bgColor
            splitIntoLines
            columns {
              svgIcon {
                iconColor
                iconType
              }
              text
              title
            }
            fieldGroupName
            text
            title
          }
          ... on WpPrograma_Acfpagefields_Content_BoxWithIconAndCta3Columns {
            bgColor
            boxColor
            borders
            fieldGroupName
            columns {
              cta {
                target
                title
                url
              }
              svgIcon {
                iconColor
                iconType
              }
              text
              title
            }
            text
            title
          }
          ... on WpPrograma_Acfpagefields_Content_BoxWithIconAndCtaVertical3Lines {
            bgColor
            boxColor
            title
            text
            fieldGroupName
            columns {
              svgIcon {
                iconColor
                iconType
              }
              cta {
                target
                title
                url
              }
            }
          }
          ... on WpPrograma_Acfpagefields_Content_TextWithImageAndCta {
            bgColor
            fieldGroupName
            invertColumns
            title
            text
            cta {
              target
              title
              url
            }
            image {
              ...FeaturedImageFields
            }
          }
          ... on WpPrograma_Acfpagefields_Content_SimpleCarousel {
            bgColor
            fieldGroupName
            imageSizes
            title
            perView
            images {
              image {
                ...FeaturedImageFields
              }
            }
          }
          ... on WpPrograma_Acfpagefields_Content_Team {
            bgColor
            description
            title
            fieldGroupName
            teamMembers {
              description
              fieldGroupName
              name
              role
              photo {
                ...FeaturedImageFields
              }
            }
          }
          ... on WpPrograma_Acfpagefields_Content_Faq {
            bgColor
            fieldGroupName
            title
            questions {
              question
              answer
            }
          }
          ... on WpPrograma_Acfpagefields_Content_BlogPosts {
            bgColor
            title
            description
            fieldGroupName
            category {
              posts {
                nodes {
                  ...PostFields
                  featuredImage {
                    node {
                      ...FeaturedImageFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
